// src/components/ProjectTile/ProjectTile.js

import React from 'react';

const ProjectTile = ({ title, children }) => {
  return (
    <div className="bg-white text-black p-6 rounded-lg shadow-md mb-8">
      <h3 className="text-xl font-semibold mb-4">{title}</h3>
      <div>{children}</div>
    </div>
  );
};

export default ProjectTile;
