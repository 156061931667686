// src/components/Contact/Contact.js

import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState('');

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formErrors = {};

    // Validate email
    if (!validateEmail(email)) {
      formErrors.email = 'Please enter a valid email address.';
    }

    // Validate subject
    if (subject.trim() === '') {
      formErrors.subject = 'Subject is required.';
    }

    // Validate message (at least 50 characters)
    if (message.trim().length < 50) {
      formErrors.message = 'Could you please add more information?';
    }

    // If there are errors, set them in the state
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      // No errors, send the email
      const templateParams = {
        from_name: email,
        to_name: 'Your Name',
        subject: subject,
        message: message,
      };

      emailjs
        .send('service_pfe1vam', 'template_l4j6upo', templateParams, 'd-YlhX-5n6vm_SRjE')
        .then(
          (response) => {
            console.log('SUCCESS!', response.status, response.text);
            setStatus('SUCCESS');
            setEmail('');
            setSubject('');
            setMessage('');
            setErrors({});
          },
          (err) => {
            console.log('FAILED...', err);
            setStatus('FAILED');
          }
        );
    }
  };

  return (
    <section className="bg-black text-white py-12" id="contact">
      <div className="max-w-[90%] mx-auto">
        <h2 className="text-2xl font-bold mb-6">Contact Me</h2>
        <form onSubmit={handleSubmit} className="space-y-6 w-full md:w-1/2">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-white">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className={`mt-1 block w-full px-3 py-2 bg-white text-black border ${
                errors.email ? 'border-red-500' : 'border-gray-300'
              } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            />
            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
          </div>
          <div>
            <label htmlFor="subject" className="block text-sm font-medium text-white">
              Subject
            </label>
            <input
              type="text"
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              className={`mt-1 block w-full px-3 py-2 bg-white text-black border ${
                errors.subject ? 'border-red-500' : 'border-gray-300'
              } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            />
            {errors.subject && <p className="text-red-500 text-sm mt-1">{errors.subject}</p>}
          </div>
          <div>
            <label htmlFor="message" className="block text-sm font-medium text-white">
              Message
            </label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              className={`mt-1 block w-full px-3 py-2 bg-white text-black border ${
                errors.message ? 'border-red-500' : 'border-gray-300'
              } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              rows="6"
            ></textarea>
            {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
          </div>
          <button
            type="submit"
            className="w-full inline-flex justify-center py-2 px-4 border border-[#3C4142] shadow-sm text-sm font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3C4142]"
          >
            Send Message
          </button>
          {status === 'SUCCESS' && <p className="text-green-500 text-sm mt-4">Your message has been sent!</p>}
          {status === 'FAILED' && <p className="text-red-500 text-sm mt-4">Something went wrong. Please try again.</p>}
        </form>
      </div>
    </section>
  );
};

export default Contact;
